#footer {
  text-align: center;
  font-style: italic;
  margin-bottom: 8px;
  width: 100%;
  a {
    text-decoration: underline;
    color: black;
  }
}
.fixed_footer {
  background-color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.policy_footer {
  margin-top: 25px;
  text-align: center;
}
