$margin-at-24: 24px;

.color-one {
  color: #0d0dba;
}
.color-two {
  color: #d15b1b;
}
.homepage-titles {
  text-align: center;
}
#container-home {
  margin-bottom: $margin-at-24;
}
.homepage-search-container {
  margin-top: 64px;
  margin-bottom: $margin-at-24;

  .search-form {
    .button-discover {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background-color: #d15b1b;
        color: white;
      }
    }
    .icon {
      position: absolute;
      top: 82%;
      transform: translateY(-50%);
      padding: 0px 0px 10px 15px;
      z-index: 1;
    }
    .input-form {
      padding-left: 45px;
    }
    .locate-me {
      display: flex;
      justify-content: space-between;
    }
    .search-form-question {
      position: relative;
    }
  }
}
.city-search {
  columns: 2 auto;
  .city-search-row {
    margin-bottom: 8px;
    a {
      width: 100%;
    }
  }
}
.homepage-listing-main {
  button {
    width: 100%;
    margin-bottom: 8px;
  }
}
.button-content {
  width: 100%;
  padding-right: 1rem;
}
.button-content::before {
  content: "🔍";
  float: left;
  width: 1rem;
}
.country_input {
  width: 65%;
  color: #495057;
  .country_selector {
    border: solid #aaa 1px;
    border-radius: 4px;
    margin-bottom: 10px;
    height: calc(1.5em + 0.75rem + 2px);
    a {
      color: #495057;
    }
  }
}
.city_input {
  padding-bottom: 6px;
  .dropdown-toggle {
    padding: 0.4rem 1rem;
  }
  .dropdown-menu.show {
    width: 100%;
  }
}
.icon_location {
  position: absolute;
  top: 79%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 34px 0px 0px 16px;
  z-index: 1;
}
.iconList {
  position: absolute;
  -webkit-transform: translateY(-50%);
  top: 54%;
  left: 88%;
  z-index: 1;
}
