// every thing related to the auto suggest https://codepen.io/moroshko/pen/XRgbxR
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-family: "Sofia";
  font-weight: normal;
  font-weight: 400;
  font-size: 0.9rem;
  border-radius: 4px;
  padding-left: 47px;
  border: 1px solid #ced4da;
  color: #495057;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #495057;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: "Sofia";
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 115px;
  overflow-y: auto;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
.card_chevron_AutoSuggest {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 85%;
  transform: translateY(-50%);
  padding: 34px 0px 0px 16px;
}
