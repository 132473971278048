@function pxToRem($pxSize) {
  @return $pxSize / 16 + rem;
}

// This mixin will generate all the css classes font-size-* for the most used sizes
@mixin font-size() {
  $font-size-list: 8 10 12 14 16 20 22 24 26 28 30;
  @each $value in $font-size-list {
    .font-size-#{$value} {
      font-size: pxToRem($value);
    }
  }
}
