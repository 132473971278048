#job-recapitulative {
  background-color: white;
  padding: 0.5rem 0;
}
.job-recapitulative-fixed {
  position: fixed;
  top: 45px;
  width: 100%;
}
.job-recap-media-main {
  width: 100%;
  .job-recap-media-img {
    margin-top: auto;
    margin-bottom: auto;
    img {
      max-width: 85px;
      max-height: 85px;
    }
  }
  .job-recap-media-body {
    max-width: 100%;
  }
}
.job-recap-title {
  font-weight: bold;
  font-size: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.job-recap-name {
  font-size: 1.4em;
  color: #d15b1b;
}
.job-recap-contract-and-date {
  display: flex;
  .job-recap-contract {
    flex: 1;
  }
  .job-recap-date {
    font-size: small;
    margin-left: auto;
  }
}
.job-recap-salary,
.job-recap-location,
.job-recap-contract {
  font-size: 1em;
}
.ellipsis-icon {
  height: 12px;
  width: 12px;
}
.ellipsis-button {
  height: 15px;
  width: 25px;
  margin-left: 4px;
  margin-right: 12px;
  margin-bottom: 4px;
  padding: 0.15rem 0rem;
  line-height: 0;
}
#job-recap-popover {
  font-size: 1rem;
}
