@font-face {
  font-family: "Sofia";
  src: url("/fonts/SofiaProRegular.eot");
  src: url("/fonts/SofiaProRegular.ttf") format("truetype"), url("/fonts/SofiaProRegular.woff") format("woff"),
    url("/fonts/SofiaProRegular.woff2") format("woff2"), url("/fonts/SofiaProRegular.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; //swap font with system font to avoid invisible text;
}

@font-face {
  font-family: "Sofia";
  src: url("/fonts/SofiaProBold.eot");
  src: url("/fonts/SofiaProBold.ttf") format("truetype"), url("/fonts/SofiaProBold.woff") format("woff"),
    url("/fonts/SofiaProBold.woff2") format("woff2"), url("/fonts/SofiaProBold.eot") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; //swap font with system font to avoid invisible text;
}

$font-size-base: 0.9rem !default;
$font-family-sans-serif: "Sofia", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
  "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji" !default;
$font-family-base: $font-family-sans-serif !default;
$btn-border-radius-lg: 0.5rem;
$link-color: #0d0dba;
$light: white;
$spacer: 0.5rem !default;

$primary: #0d0dba;
$secondary: #d15b1b;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/transitions";
@import "auto_suggest_style";
@import "homepage_style";
@import "job_recapitulative_style";
@import "footer";
@import "mixins";

@include font-size;

.btn {
  border-radius: 25px;
}
#apply {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1020;
  background-color: white;
}
#apply,
#end-apply {
  padding: 0.5rem 0;
}
.utm_source_snapchat,
.utm_source_tiktok {
  #apply,
  #end-apply {
    padding-bottom: 50px;
  }
  .apply-view {
    margin-bottom: 30px;
  }
}
.apply-success {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .apply-success-main {
    flex: 1;
    margin-top: 3rem;
    margin-bottom: 16px;
    .description {
      min-height: 100%;
    }
  }
}
#end-apply {
  position: fixed;
  bottom: 0;
  left: 15px;
  width: 100%;
  z-index: 1020;
  padding-right: 30px !important;
  background-color: white !important;
}
// #end-apply button
//   background-color: white !important
.btn-apply {
  @include button-variant(#d15b1b, #d15b1b);
}
.w-100 {
  font-weight: 600;
  font-size: 1.3rem;
}
.w-full {
  width: 100% !important;
}
.h-full {
  height: 100% !important;
}
.btn-apply-sec {
  @include button-outline-variant(#d15b1b, #d15b1b, white, #d15b1b);
}
.btn-sec {
  @include button-outline-variant(#0d0dba, #0d0dba, white, #0d0dba);
}
.navbar {
  border-bottom: $hr-border-width solid $hr-border-color;
  .container {
    width: 90%;
  }
}
.dropdown-menu {
  width: 100%;
  padding: 0;
  a {
    font-style: italic;
    color: #383839;
    &:hover {
      background-color: #ffead8;
    }
  }
}
.section_title {
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  border-top: $hr-border-width solid $hr-border-color;
}
.cv_uploader_info {
  font-size: small;
  color: grey;
  margin-bottom: -8px;
}
.attached_icon {
  height: 15px;
  width: 15px;
  margin-right: 8px;
}
.section:first-child .section_title {
  border-top: none;
}
.form-label {
  font-weight: bold;
}
#optional {
  font-weight: lighter;
  font-size: small;
  color: grey;
  margin-left: 8px;
}
.widget_dates {
  word-wrap: normal;
}
#cookies_types_table {
  width: 100%;
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
  }
}
.card_title {
  vertical-align: middle;
  float: left;
  width: 90%;
}
.accordion_toggle {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 0px;
  .card_chevron {
    vertical-align: middle;
    float: right;
  }
}
.accordion_chevron {
  height: 20px;
  width: 20px;
  color: gray;
}
.job_recap_icon {
  height: 15px;
  width: 15px;
}
.error {
  margin-top: 60px;
  .error_title {
    text-align: center;
    h2 {
      margin-top: 20px;
    }
  }
  .error_content {
    text-align: center;
    p {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
.policy {
  display: flex;
  min-height: 95vh;
  flex-direction: column;
  margin-top: 50px;
  .policy_title {
    text-align: center;
    p {
      font-style: italic;
    }
  }
  .policy_main {
    flex: 1;
  }
  .policy_footer button {
    margin-bottom: 10px;
  }
}
.cookie_policy_title p {
  font-style: italic;
}
.section_cv_uploader_section {
  position: relative;
  text-align: center;
  button {
    z-index: -1;
    position: relative;
    width: 100%;
    bottom: 35px;
  }
  span {
    position: relative;
    bottom: 20px;
  }
  span:nth-child(5) {
    color: grey;
  }
}
.custom-file-input {
  z-index: 0;
}
#job-description {
  padding: 0.5rem 0;
  overflow: hidden;
}
#job-page-main {
  margin-top: 3rem;
}
#close-application-form {
  position: fixed;
  top: 188px;
  right: 0.5rem;
  z-index: 1080;
}
#job_offer {
  text-align: center;
}
.description {
  margin-bottom: 65px;
  .title {
    font-weight: bold;
    font-size: 1.4rem;
  }
  .dash-icon {
    height: 12px;
    width: 12px;
    color: #d15b1b;
    margin-right: 4px;
  }
  hr {
    margin-top: 0rem;
  }
  .value > * {
    display: block;
  }
  .value * {
    max-width: 100%;
    word-break: break-word !important;
    word-wrap: break-word !important;
    white-space: pre-line;
  }
}
#consent {
  font-style: italic;
  font-size: 0.8rem;
  text-align: center;
  padding-bottom: 100px;

  a {
    color: black;
    text-decoration: underline;
  }
}
.invalid-feedback {
  display: block;
}
#check {
  float: left;
  width: 67px;
}
.card {
  margin-bottom: 8px;
  cursor: pointer;
}
#application_sent {
  font-size: 1.2rem;
  padding-top: 32px;
}
.apply-view {
  margin-top: 50px;
}
.align_center {
  text-align: center;
}
.final_step {
  clear: both;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 32px;
}
.final_step_title {
  font-size: 2rem;
  font-weight: bold;
  color: #d15b1b;
}
.modal-header {
  button.close {
    span {
      display: none;
    }
    span.sr-only {
      display: block;
    }
  }
}
.modal-body {
  p {
    font-size: 0.6rem;
  }
}
.utm_source_tiktok {
  .modal-body {
    p {
      font-size: 0.75rem;
    }
  }
}
.modal-footer {
  justify-content: space-between;
}
.logo {
  height: 27px;
}
.card-body {
  padding: 0.6rem;
}
.job-list-results {
  text-align: center;
  font-size: 1.5rem;
}
.job-list-container {
  margin-top: 8px;
}
.job-list-element-img {
  max-width: 110px;
}

.job-list-title {
  text-align: center;
  font-size: 1.2rem;
  .job-list-title-blue {
    font-weight: bold;
    color: #0d0dba;
  }
  .job-list-title-orange {
    font-weight: bold;
    color: #d15b1b;
    padding-bottom: 16px;
  }
}
.job-list-title-and-date {
  margin: 0 auto;
  display: flex;
  .job-list-date {
    text-align: right;
    padding-left: 10px;
    width: fit-content;
    font-size: 0.8em;
  }
}
.job-title,
.job-list-element-title {
  font-size: 1.4em;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 10px;
}
.job-list-element-title {
  margin: 0 auto;
  display: flex;
  flex: 1;
  font-size: 1.2em;
}
.job-list-details {
  width: 100%;
}
.job-list-company-name {
  font-size: 1.2em;
  color: #020101;
}
.job-list-info-and-button {
  width: 100%;
  margin: 0 auto;
  display: flex;
  .job-list-info {
    color: rgb(76, 76, 76);
    flex: 1;
  }
  .job-list-button {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    button {
      width: fit-content;
      background-color: #d15b1b;
      color: white;
      line-height: 1;
    }
  }
}
.job-list-location,
.job-list-salary,
.job-list-contract {
  font-size: 1em;
  width: 55%;
}
// .x-application-form-enter
//   // margin-top: 200%
// .x-application-form-enter-done
//   // margin-top: 0%
//   top: 154px
// .job-description
//   max-height: 100%
//   overflow: hidden
//   transition: all 500ms ease
//   // margin-top: 0
//   // transform: scaleY(1)
//   // transform-origin: top
//   max-height: auto
// .job-description-enter
//   // margin-top: -100%
//   // transform: scaleY(0)
//   max-height: 0
// .job-description-enter-done
//   margin-top: 0
//   // transform: scaleY(1)
//   max-height: auto
// .job-description-exit
//   // transform: scaleY(1)
//   max-height: auto
// .job-description-exit-done
//   // transform: scaleY(0)
//   max-height: 0
// .application-form
//   position: relative
//   z-index: 1025
//   background-color: white
//   margin-bottom: 0
//   max-height: 0
//   overflow: hidden
//   transition: max-height 500ms ease
// .application-form-enter
//   max-height: 0
//   margin-bottom: 0
// .application-form-enter-done
//   max-height: 100%
//   margin-bottom: -64px
